// ======================================
//  Landing Styles & Layout
// ======================================

.landing {
    @include flexer($dir: column, $just: center, $ai: center);
    &-header {
        width: 100vw;
        h1 {
            @include center;
            padding-top: ($header-offset/6);
            width: 100vw;
        }
    }
    &-heading {
        margin: $gutter;
    }
    &-form {
        @include center(40%);
        margin-top: $gutter;
        @include flexer($dir: column, $just: center, $ai: center, $as: center);
    }
    &-card {
        width: 30vw;
        min-width: 200px;
        margin-bottom: $gutter;
        p {
            margin-top: $gutter/2;
        }
        input {
            margin-bottom: $gutter/2;
        }
        @include mq('sm') {
            margin-left: -5vw;
        }
    }
    &-btn {
        width: 100px;
        height: 30px;
    }
    &-view {
        strong {
            cursor: pointer;
        }
    }
}

.form-actions {
    width: 250px;
    @include flexer($dir: row, $just: space-evenly);
}

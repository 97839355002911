// ======================================
//  Vocab Styles & Layout
// ======================================

// Vocab Button Styles
$choices-width: 600px;
$choices-width-mq: 70vw;
$choices-height-mq: 80px;

.choice {
    width: ($choices-width/4);
    height: 50px;
    @include mq('med') {
        width: $choices-width-mq;
        height: $choices-height-mq;
    }
}

.action {
    width: $choices-width;
    height: 50px;
    background-color: $color-secondary;
    color: $color-primary;
    @include mq('med') {
        width: $choices-width-mq;
        height: $choices-height-mq;
    }
}

// Vocab Text Styles
.vocab-answered {
    .vocab-correct {
        font-weight: bold;
        color: green;
    }
    .vocab-incorrect {
        font-weight: bold;
        color: red;
    }
}

.query-word {
    color: $color-primary;
    font-weight: bold;
}

.user-response {
    height: 25vh;
    @include mq('med') {
        height: 460px;
    }
}

// Vocab Layout
.vocab {
    h1 {
        margin: $gutter;
    }
    &-card {
        width: $card-width;
        height: 20vh;
        @include mq('med') {
            height: 250px;
        }
    }
    &-answered {
        height: 20vh;
    }
    &-word {
        margin: $gutter;
    }
    @include flexer($dir: column, $just: center);
    &-card {
        p {
            margin: $gutter;
        }
        @include flexer($dir: column, $just: space-between);
    }
    &-choices {
        @include flexer($dir: row, $just: center);
        @include mq('med') {
            @include flexer($dir: column, $just: center, $ai: center)
        }
    }
    &-answered {
        p {
            margin: $gutter;
        }
    }
}

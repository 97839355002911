// ======================================
//  Base Styles
// ======================================

* {
    box-sizing: border-box;
}

body {
    color: $color-text;
    font-size: 1rem;
    line-height: 1.5;
    font-family: $font-stack-primary;
    text-align: center;
    margin: 0;
    background-color: $color-bg;
}

h1, h2 {
    font-family: $font-stack-secondary;
    color: $color-primary;
    font-weight: bold;
    font-size: 16pt;
}

// p {
//     margin-bottom: 1.25em;
// }

a {
    color: $color-primary;
    text-decoration: none;
    font-family: $font-stack-secondary;
    font-weight: bold;
    &:hover {
        color: $color-accent;  
    }
}

strong {
    font-weight: bold;
    color: $color-accent;
}

header {
    background-color: $color-secondary;
    height: $header-offset;
}
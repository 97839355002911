// ======================================
//  Variables
// ======================================

// Colors
// cf. color palette #12: https://visme.co/blog/website-color-schemes/
$color-primary: #66FCF1;
$color-secondary: #0B0C10;
$color-accent: #45A29E;
$color-text: #C5C6C7;
$color-card: #34495E;
$color-bg: #1F2833;

// Fonts
$font-stack-primary: 'Raleway', sans-serif;
$font-stack-secondary: 'Bree Serif', serif;

// Layout
$max-width: 50vw;
$header-offset: 5vh;
$gutter: 10px;
$card-width: 35vw;

// Sections Prefixes
$sections: (
    'landing',
    'header',
    'vocab',
    'profile',
    'about',
    'invalid'
);

// Media Query Breakpoints
$breakpoints: (
    'xs': 424px,
    'sm': 425px,
    'med': 768px,
    'lg': 992px,
);

// =========================================
//  Mixins
// =========================================

// Centered containers
@mixin center($w: 90%) {
    width: $w;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}

// Flexbox containers
@mixin flexer(
    $disp: flex,
    $dir: null,
    $wrap: null,
    $just: null,
    $ai: null,
    $ac: null,
    $as: null) {
    display: $disp;
    flex-direction: $dir;
    flex-wrap: $wrap;
    justify-content: $just;
    align-items: $ai;
    align-content: $ac;
    align-self: $as;
}

// Media queries
@mixin mq($break) {
    $value: map-get($breakpoints, $break);
    $med: map-get($breakpoints, 'med');

    @if $value > $med {
        @media (min-width: $value) {
            @content;
        }
    }
    @else {
        @media (max-width: $value) {
            @content;
        }
    }
}


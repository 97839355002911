// ======================================
//  Profile Styles & Layout
// ======================================

.profile-stats {
    color: $color-primary;
    font-weight: bold;
}

.profile-table {
    @include center(85%);
    th {
        color: $color-accent;
        font-weight: bold;
    }
    .profile-total {
        color: $color-primary;
    }
    margin-bottom: 1em;
    margin-top: 0.5em;
    // @include flexer($dir: column, $as: center, $just: space-evenly)
}

canvas {
    @include center(75%);
    max-width: 20vw;
    max-height: 25vh;
}

$admin-width: 200px;
$admin-height: 50px;

.administration, .admin-affirm, .admin-negate {
    width: $admin-width;
    height: $admin-height;
}

.admin-affirm {
    background-color: red;
}

.admin-negate {
    background-color: green;
}

.warning {
    color: red;
    font-weight: bold;
}

$mq-width: 70vw;

// Layout
.profile {
    @include flexer($dir: column, $just: center, $ai: center);
    &-admin {
        height: 230px;
    }
    &-card, &-summary {
        margin: $gutter;
    }
    &-card {
        width: $card-width;
        @include mq('med') {
            width: 500px;
        }
    }
    &-summary {
        width: 36.5vw;
        margin: 0;
        @include mq('med') {
            width: $mq-width;
        }
    }
    h2 {
        margin: 5px;
    }
    h1 {
        margin-top: $gutter;
    }
    &-btn {
        margin-top: $gutter;
        width: 15vw;
        min-width: 100px;
        max-width: 150px;
    }
    &-data {
        @include flexer($dir: row, $just: center);
        height: 30vh;
        @include mq('med') {
            width: $mq-width;
            height: 530px;
            @include flexer($dir: column, $just: center);
        }
    }
    &-card, &-summary {
        @include flexer($dir: column, $just: center, $as: flex-start);
    }
    .view-stats {
        // width: 20vw;
        @include center;
        p {
            margin: 5px;
            width: 35vw;
        }
        @include mq('med') {
            @include flexer($dir: column, $just: center, $ai: center);
        }
    }
}

.view-table, .view-chart {
    @include mq('med') {
        width: 600px;
        @include flexer($as: center);
    }
}

.profile-table {
    @include mq('sm') {
        margin-left: -18vw;
    }
}

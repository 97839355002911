// ======================================
//  Button Styles
// ======================================

// General Button Styles
@each $section in $sections {
    .#{$section}-btn {
        font-family: $font-stack-primary;
        background-color: $color-accent;
        color: $color-secondary;
        text-align: center;
        border-radius: 8px;
        text-decoration: none;
        overflow-wrap: break-word;
        border-color: $color-text;
        cursor: pointer;
    }
}

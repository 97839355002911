// ======================================
//  App Header Styles & Layout
// ======================================

.app-header {
    width: 100vw;
    @include flexer($dir: column, $just: center, $ai: center)
}

.header-contents {
    height: $header-offset;
    width: 75vw;
    @include flexer($dir: row, $just: space-between, $ai: center, $as: center);
}

nav {
    width: 50vw;
    @include flexer($just: space-between)
}
